import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Chip,
  Collapse,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colorStyling } from "../../../helpers/color";
import {
  SET_LOADING_CMS,
  SET_FORCE_LOAD,
} from "../../../store/actionTypes/cmsActionType";
import { initiateAXIOS } from "../../../config/axios";
import { initiateUserAPI } from "../../../api";
import { logger } from "../../../helpers/logger";
import { TypographySmall, TypographyXSmall } from "../../customComponent";
import parser from "html-react-parser";

const ContentTemplateFilter = ({
  filter,
  setFilter,
  setSorting,
  resetFilter,
}) => {
  const dispatch = useDispatch();
  const { forceLoad } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [searchNameInput, setSearchNameInput] = useState("");
  const [searchByEditorInput, setSearchByEditorInput] = useState("");
  const [sortBy, setSortBy] = useState("idDesc");
  const [anchorEl, setAnchorEl] = useState(null);
  const openSortButton = Boolean(anchorEl);

  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
  const [searchByTagInputs, setSearchByTagInputs] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [tagValidation, setTagValidation] = useState("");

  const handleToggleAdvancedFilter = () => {
    setOpenAdvancedFilter(!openAdvancedFilter);
  };

  const handleTagInputChange = (event) => {
    const input = event.target.value;
    // max input to 20 characters
    if (input?.length > 20) {
      setTagValidation(t("filter.tagInputReachedMax"));
      const textAfter = input.slice(0, 20);
      setTagInput(textAfter);
      return;
    }
    setTagValidation("");
    setTagInput(input);
  };

  const handleTagInputPress = (event) => {
    if (event.key === "Backspace" && tagInput === "") {
      if (searchByTagInputs.length === 0) {
        return;
      }

      const updatedTags = [...searchByTagInputs];
      updatedTags.pop();
      setSearchByTagInputs(updatedTags);
      return;
    }
    if (event.key === "Enter") {
      const tag = tagInput.trim();
      if (tag === "") {
        setTagInput("");
        return;
      }
      if (searchByTagInputs.includes(tag)) {
        setTagValidation(t("submitDialog.tagErrorAlreadyExist"));
        return;
      }
      if (/[^a-zA-Z0-9]/.test(tag)) {
        setTagValidation(t("submitDialog.tagErrorInvalid"));
        return;
      }

      setSearchByTagInputs([...searchByTagInputs, tagInput.trim()]);
      setTagValidation("");
      setTagInput("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setSearchByTagInputs(
      searchByTagInputs.filter((tag) => tag !== tagToRemove)
    );
  };

  useEffect(() => {
    setSortBy(filter.sortBy);
    setSearchNameInput(filter.nameLike);
    setSearchByEditorInput(filter.searchByUser);
    if (filter.searchByTags?.length > 0) {
      setSearchByTagInputs(filter.searchByTags);
      setOpenAdvancedFilter(true);
    }
  }, [filter]);

  const getUserIDNameAlikes = async (nameInput, resolve) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_CMS, payload: true });
    dispatch({ type: SET_FORCE_LOAD, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateUserAPI + `?nameLike=${nameInput}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let userIDs = [];
      data.items.forEach((user) => userIDs.push(user.id));
      resolve(userIDs);
    } catch (error) {
      logger.error(`error:`, error);
    }
  };

  const handleChangeSortBy = (value) => {
    setSortBy(value);
    setSorting(encodeURIComponent(value));
    setAnchorEl(null);
    dispatch({ type: SET_FORCE_LOAD, payload: true });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newFilter = {
      nameLike: searchNameInput,
      searchByUserIDs: [],
      searchByTags: [],
    };

    if (searchByEditorInput.length > 0) {
      getUserIDNameAlikes(searchByEditorInput, (userIDs) => {
        newFilter["searchByUserIDs"] = userIDs;
        newFilter["searchByUser"] = searchByEditorInput;
        setFilter(newFilter);
      });
      return;
    } else {
      newFilter["searchByUserIDs"] = [];
      newFilter["searchByUser"] = "";
    }

    if (searchByTagInputs.length > 0) {
      newFilter["searchByTags"] = searchByTagInputs;
    }

    dispatch({ type: SET_FORCE_LOAD, payload: true });
    setFilter(newFilter);
  };

  const resetState = () => {
    setSearchNameInput("");
    setSearchByEditorInput("");
    setSortBy("idDesc");
    setSearchByTagInputs([]);
    setTagInput("");
  };

  const handleRefresh = () => {
    dispatch({ type: SET_FORCE_LOAD, payload: true });
    resetState();
    resetFilter();
  };

  return (
    <Box>
      <Paper
        component="section"
        elevation={2}
        sx={{
          p: 2,
          mt: 2,
          borderRadius: "6px",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box width={"50%"}>
            <TextField
              type="Search"
              size="small"
              fullWidth
              label={t("filter.searchNameLabel")}
              placeholder={t("filter.searchPlaceholder")}
              value={searchNameInput}
              onChange={(e) => setSearchNameInput(e.target.value)}
              disabled={forceLoad}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {/* TODO : Add Modified Date Filter  */}
          {/* <Box width={"22%"}>
            <TextField
              onChange={(e) => setSearchByEditorInput(e.target.value)}
              value={searchByEditorInput}
              fullWidth
              type="Search"
              placeholder={t("filter.searchByEditorPlaceholder")}
              size="small"
              disabled={forceLoad}
            />
          </Box> */}

          <Box width={"27%"}>
            <TextField
              type="Search"
              size="small"
              fullWidth
              label={t("filter.searchEditorLabel")}
              placeholder={t("filter.searchByEditorPlaceholder")}
              value={searchByEditorInput}
              onChange={(e) => setSearchByEditorInput(e.target.value)}
              InputLabelProps={{ shrink: true }}
              disabled={forceLoad}
            />
          </Box>

          <Box display={"flex"} width={"177px"}>
            <Button variant="contained" type="submit" disabled={forceLoad}>
              {tCommon("searchBtn")}
            </Button>

            <IconButton
              onClick={handleClick}
              disabled={forceLoad}
              sx={{ ml: 2, color: colorStyling.black }}
            >
              <Tooltip title={tCommon("sortTip")}>
                <SortIcon fontSize="small" />
              </Tooltip>
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openSortButton}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
                <FormControlLabel
                  disableTypography
                  value="idDesc"
                  control={<Radio size="small" checked={sortBy === "idDesc"} />}
                  label={t("filter.sortByNewest")}
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>

              <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
                <FormControlLabel
                  disableTypography
                  value="idAsc"
                  control={<Radio size="small" checked={sortBy === "idAsc"} />}
                  label={t("filter.sortByOldest")}
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>

              <MenuItem onClick={() => handleChangeSortBy("updatedAtDesc")}>
                <FormControlLabel
                  disableTypography
                  value="updatedAtDesc"
                  control={
                    <Radio size="small" checked={sortBy === "updatedAtDesc"} />
                  }
                  label={t("filter.sortByRecentlyModified")}
                  sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
                />
              </MenuItem>
            </Menu>

            <IconButton
              onClick={handleRefresh}
              disabled={forceLoad}
              sx={{ ml: 1, color: colorStyling.black }}
            >
              <Tooltip title={tCommon("resetFilterTip")}>
                <RestartAltIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </Box>
        </Box>

        <Collapse in={openAdvancedFilter}>
          <Box
            sx={{
              mt: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box width={"50%"}>
              <TextField
                type="Search"
                size="small"
                fullWidth
                autoComplete="off"
                label={t("filter.searchTagsLabel")}
                placeholder={t("filter.searchByTagsPlaceholder")}
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyDown={handleTagInputPress}
                InputLabelProps={{ shrink: true }}
                disabled={forceLoad}
                InputProps={{
                  startAdornment: (
                    <Box
                      display="flex"
                      gap={1}
                      height={"auto"}
                      maxWidth={"200px"}
                      sx={{
                        mr: 1,
                        my: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {searchByTagInputs.map((item, index) => (
                        <Chip
                          key={index}
                          label={item}
                          size="small"
                          color="success"
                          onDelete={() => handleTagRemove(item)}
                          sx={{
                            color: "white",
                            backgroundColor: "mediumseagreen",
                          }}
                        />
                      ))}
                    </Box>
                  ),
                }}
              />
              <TypographyXSmall sx={{ ml: 1 }}>
                {parser(t("submitDialog.contentTagsHint"))}
              </TypographyXSmall>

              <TypographySmall
                sx={{
                  ml: 1,
                  color: colorStyling.red,
                  whiteSpace: "nowrap",
                }}
              >
                {tagValidation}
              </TypographySmall>
            </Box>
          </Box>
        </Collapse>
      </Paper>

      <Box sx={{ mb: 2, ml: 2 }}>
        <Tooltip arrow disableInteractive title={t("filter.openAdvancedTip")}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleToggleAdvancedFilter}
            sx={{ p: 0, mt: "-4px", borderRadius: "0 0 10px 10px" }}
          >
            {openAdvancedFilter ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default ContentTemplateFilter;
