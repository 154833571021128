import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, ButtonBase, InputLabel, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { default as LandscapeIcon } from "@mui/icons-material/NoteOutlined";
import { default as PotraitIcon } from "@mui/icons-material/InsertDriveFileOutlined";
import { TypographyLarge, TypographySmall } from "../../../customComponent";
import { ResolutionType } from "../../../../helpers/resolution";
import { inputOnlyNumbers } from "../../../../helpers/inputProcessing";
import {
  setContentLayers,
  setContentResolution,
  setContentResolutionRatio,
  setCanvasRes,
  setContentName,
  setErrorHandler,
} from "../../../../store/actions/cmsAction";
import update from "immutability-helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { colorStyling } from "../../../../helpers/color";
import { logger } from "../../../../helpers/logger";
import parser from "html-react-parser";
import { parseVariables } from "../../../../helpers/localize";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const Form = ({ resType, setResType, nameValidation, setNameValidation }) => {
  const dispatch = useDispatch();
  const {
    contentLayers,
    contentName,
    contentRes,
    canvasRes,
    errorHandler,
    isEditing,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [widthInput, setWidthInput] = useState(360);
  const [heightInput, setHeightInput] = useState(640);
  const [orientation, setOrientation] = useState("P");
  const [manualResChange, setManualResChange] = useState(false);
  const [resInputHelp, setResInputHelp] = useState(false);
  const [resMinMaxHelp, setResMinMaxHelp] = useState(false);

  const layerCount = useMemo(() => {
    return Object.keys(contentLayers).length;
  }, [contentLayers]);

  useEffect(() => {
    if (contentRes.label !== "") {
      setWidthInput(contentRes.width);
      setHeightInput(contentRes.height);
      setOrientation(contentRes.orientation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCanvasRes = (resolution, orientation, freeFlag) => {
    setResInputHelp(false);
    setResMinMaxHelp(false);

    let updatedCanvasWidth;
    let updatedCanvasHeight;

    let potraitFlag = false;
    if (orientation === "P") {
      potraitFlag = true;
    }

    if (!freeFlag) {
      updatedCanvasWidth = potraitFlag ? 360 : 640;
      updatedCanvasHeight = potraitFlag ? 640 : 360;
    } else {
      updatedCanvasWidth = resolution.width;
      updatedCanvasHeight = resolution.height;
    }

    dispatch(
      setCanvasRes({
        ...update(canvasRes, {
          $merge: {
            width: updatedCanvasWidth,
            height: updatedCanvasHeight,
            orientation: orientation,
          },
        }),
      })
    );

    resetAllLayers(resolution, {
      width: updatedCanvasWidth,
      height: updatedCanvasHeight,
    });
  };

  useEffect(() => {
    if (isEditing) {
      setWidthInput(contentRes.width);
      setHeightInput(contentRes.height);
      setOrientation(contentRes.orientation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    let widthRatio = 1;
    let heightRatio = 1;
    if (!contentRes.free) {
      widthRatio = contentRes.width / canvasRes.width;
      heightRatio = contentRes.height / canvasRes.height;
    }

    dispatch(setContentResolutionRatio({ widthRatio, heightRatio }));

    if (manualResChange) {
      let copyLayers = { ...contentLayers };
      const layerKeys = Object.keys(contentLayers);
      layerKeys.forEach((layerID) => {
        const layerDim = contentLayers[layerID].dimensions;

        let adjustedWidth = layerDim.width;
        const ratiodLeft = Math.round(layerDim.left * widthRatio);
        if (layerDim.width > contentRes.width - ratiodLeft) {
          const widthLimited = contentRes.width - ratiodLeft;
          adjustedWidth = widthLimited;
        }

        let adjustedHeight = layerDim.height;
        const ratiodTop = Math.round(layerDim.top * heightRatio);
        if (layerDim.height > contentRes.height - ratiodTop) {
          const heightLimited = contentRes.height - ratiodTop;
          adjustedHeight = heightLimited;
        }

        const originalAspectRatio = layerDim.width / layerDim.height;
        if (layerDim.lockRatio) {
          if (adjustedWidth / adjustedHeight > originalAspectRatio) {
            adjustedWidth = Math.round(adjustedHeight * originalAspectRatio);
          } else {
            adjustedHeight = Math.round(adjustedWidth / originalAspectRatio);
          }
        }

        const overflowed = { width: adjustedWidth, height: adjustedHeight };
        copyLayers = update(copyLayers, {
          [layerID]: { dimensions: { $merge: overflowed } },
        });
      });

      dispatch(setContentLayers({ ...copyLayers }));
      setManualResChange(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentRes, canvasRes, manualResChange, resType]);

  const resetAllLayers = (resolution, updatedCanvas) => {
    const { width, height } = resolution;
    const canvas = updatedCanvas ?? canvasRes;

    let copyLayers = { ...contentLayers };
    const layerKeys = Object.keys(contentLayers);
    layerKeys.forEach((layerID) => {
      const layerDim = contentLayers[layerID].dimensions;
      const widthRatio = width / canvas.width;
      const heightRatio = height / canvas.height;

      let textUpdated = {};
      let adjustedWidth = layerDim.width;
      let adjustedHeight = layerDim.height;
      const currentText = contentLayers[layerID].text;
      if (currentText) {
        adjustedWidth = width;
        adjustedHeight = Math.round(50 * heightRatio);
        textUpdated["text"] = {};
        textUpdated["text"]["size"] = Math.round(22 * heightRatio);
      } else {
        adjustedWidth = Math.round(200 * widthRatio);
        adjustedHeight = Math.round(200 * heightRatio);
      }

      let lockRatioFlag = undefined;
      if (layerDim.lockRatio && !contentRes.free) {
        const originalAspectRatio = layerDim.width / layerDim.height;
        if (adjustedWidth / adjustedHeight > originalAspectRatio) {
          adjustedWidth = Math.round(adjustedHeight * originalAspectRatio);
        } else {
          adjustedHeight = Math.round(adjustedWidth / originalAspectRatio);
        }
      } else {
        lockRatioFlag = false;
      }

      copyLayers = update(copyLayers, {
        [layerID]: {
          dimensions: {
            $merge: {
              top: 0,
              left: 0,
              width: adjustedWidth,
              height: adjustedHeight,
              lockRatio: lockRatioFlag,
            },
          },
        },
      });

      if (textUpdated.text) {
        copyLayers = update(copyLayers, {
          [layerID]: { text: { $merge: textUpdated.text } },
        });
      }
    });

    logger.log(`reset:`, { ...copyLayers });
    dispatch(setContentLayers({ ...copyLayers }));
  };

  const handleSetOrientation = useCallback(
    async (value) => {
      if (orientation === value) {
        return;
      }

      if (layerCount > 0) {
        const { isConfirmed } = await withReactContent(Swal).fire({
          title: tCommon("confirmationLabel"),
          html: t("designer.orientationChangeSwalText"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: colorStyling.cms.blue,
          cancelButtonColor: colorStyling.cms.red,
          confirmButtonText: tCommon("yesBtn"),
          cancelButtonText: tCommon("cancelBtn"),
          reverseButtons: true,
        });

        if (!isConfirmed) {
          return;
        }
      }

      let resSwitchOri;
      if (!contentRes.custom) {
        resSwitchOri = `${contentRes.height} x ${contentRes.width}`;
      } else {
        resSwitchOri = contentRes.label;
      }

      const resChosen = ResolutionType[value][resSwitchOri];
      if (!resChosen) {
        dispatch(
          setContentResolution(
            update(contentRes, { $merge: { orientation: value } })
          )
        );
        return;
      }

      // Notice how it's just the other input, because this only when changing orientation
      let updatedWidth = !contentRes.custom
        ? resChosen.width
        : contentRes.height;
      let updatedHeight = !contentRes.custom
        ? resChosen.height
        : contentRes.width;
      const custom = resChosen.custom;
      const freeResFlag = resChosen.free;

      setOrientation(value);
      handleCanvasRes(
        { width: updatedWidth, height: updatedHeight },
        value,
        freeResFlag
      );

      dispatch(
        setContentResolution(
          update(contentRes, {
            $merge: {
              width: updatedWidth,
              height: updatedHeight,
              custom,
              orientation: value,
              label: resSwitchOri,
              free: freeResFlag,
            },
          })
        )
      );

      setResType(resSwitchOri);
      setWidthInput(updatedWidth);
      setHeightInput(updatedHeight);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resType, orientation, contentRes, canvasRes, contentLayers]
  );

  const handleSelectedResType = useCallback(
    async (e) => {
      const selectedRes = e.target.value;
      if (selectedRes?.length === 0) {
        return;
      }

      if (resType !== "" && layerCount > 0) {
        const { isConfirmed } = await withReactContent(Swal).fire({
          title: tCommon("confirmationLabel"),
          html: t("designer.resolutionChangeSwalText"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: colorStyling.cms.blue,
          cancelButtonColor: colorStyling.cms.red,
          confirmButtonText: tCommon("yesBtn"),
          cancelButtonText: tCommon("cancelBtn"),
          reverseButtons: true,
        });

        if (!isConfirmed) {
          return;
        }
      }

      setResType(selectedRes);
      const resChosen = ResolutionType[orientation][selectedRes];
      const { width, height, custom, free } = resChosen;
      setWidthInput(width);
      setHeightInput(height);
      dispatch(
        setContentResolution(
          update(contentRes, {
            $merge: { width, height, custom, free, label: selectedRes },
          })
        )
      );

      dispatch(setErrorHandler({ resolution: false }));
      handleCanvasRes({ width, height }, orientation, free);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orientation, contentLayers, contentRes, canvasRes]
  );

  const handleChangeReso = useCallback(
    (which, event) => {
      setResInputHelp(true);
      setResMinMaxHelp(false);

      let value = event?.target?.value ?? event;
      const valueString = `${value}`;
      if (!value || valueString.length === 0) {
        switch (which) {
          case "width":
            setWidthInput(value);
            return;

          case "height":
            setHeightInput(value);
            return;

          default:
            return;
        }
      }

      value = parseInt(value);
      if (value > 50000) {
        value = 50000;
      }

      const currentRes =
        ResolutionType[contentRes.orientation][contentRes.label];
      const updated = { width: contentRes.width, height: contentRes.height };
      const whichRatio = currentRes.width / currentRes.height;

      switch (which) {
        case "width":
          setWidthInput(value);
          updated["width"] = value;

          if (!contentRes.free) {
            updated["height"] = Math.round(value / whichRatio);
            setHeightInput(updated["height"]);
          }
          break;

        case "height":
          setHeightInput(value);
          updated["height"] = value;

          if (!contentRes.free) {
            updated["width"] = Math.round(value * whichRatio);
            setWidthInput(updated["width"]);
          }
          break;

        default:
          return;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentRes, widthInput, heightInput]
  );

  const applyResolutionChanges = () => {
    setResInputHelp(false);
    if (!(widthInput >= 50 && heightInput >= 50)) {
      setResMinMaxHelp(true);
      return;
    }

    const updated = {
      width: widthInput,
      height: heightInput,
    };

    if (contentRes.free === true) {
      dispatch(setCanvasRes({ ...update(canvasRes, { $merge: updated }) }));
    }

    const widthRatio = updated.width / canvasRes.width;
    const heightRatio = updated.height / canvasRes.height;
    dispatch(setContentResolutionRatio({ widthRatio, heightRatio }));
    dispatch(setContentResolution(update(contentRes, { $merge: updated })));
    setManualResChange(true);
    setResMinMaxHelp(false);
  };

  const handleChangeName = (event) => {
    const input = event.target.value;
    if (input.length > 40) {
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    } else {
      dispatch(setContentName(input));
      if (nameValidation) setNameValidation("");
    }
    dispatch(setErrorHandler({ contentName: false }));
  };

  const resApplyCondition = useMemo(() => {
    if (!contentRes.custom) {
      return false;
    }

    let flag = false;
    if (widthInput !== contentRes.width) {
      flag = true;
    }
    if (heightInput !== contentRes.height) {
      flag = true;
    }
    return flag;
  }, [widthInput, heightInput, contentRes]);

  return (
    <>
      <StyledBox>
        <InputLabel>{t("designer.contentNameLabel")}</InputLabel>

        <TextField
          margin="dense"
          fullWidth
          variant="outlined"
          placeholder={t("designer.contentNamePlaceholder")}
          size="small"
          value={contentName}
          error={errorHandler.contentName}
          onChange={(e) => handleChangeName(e)}
          onBlur={() => setNameValidation("")}
        />
        <TypographySmall sx={{ color: colorStyling.red }}>
          {nameValidation}
        </TypographySmall>
      </StyledBox>

      {resType !== "" && (
        <StyledBox>
          <InputLabel>{t("designer.orientationLabel")}</InputLabel>

          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={3}
            sx={{ mt: "8px" }}
          >
            <ButtonBase
              sx={{
                p: 2,
                width: "112px",
                borderRadius: "8px",
                border:
                  orientation === "P"
                    ? "2px solid rgba(28, 78, 216, 0.9)"
                    : "2px solid rgba(28, 78, 216, 0.6)",
                backgroundColor:
                  orientation === "P"
                    ? "rgba(28, 78, 216, 0.1)"
                    : "transparent",
                ".potraitIcon": {
                  color:
                    orientation === "P"
                      ? colorStyling.primary
                      : colorStyling.black,
                },
                ":hover": {
                  border:
                    orientation === "P"
                      ? ""
                      : "2px solid rgba(28, 78, 216, 0.6)",
                  backgroundColor: "rgba(28, 78, 216, 0.1)",
                },
              }}
              onClick={() => {
                handleSetOrientation("P");
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <PotraitIcon
                  className="potraitIcon"
                  fontSize="inherit"
                  color="inherit"
                  sx={{ fontSize: "64px", transform: "scaleX(-1)" }}
                />
                <TypographyLarge
                  sx={{
                    color:
                      orientation === "P"
                        ? colorStyling.primary
                        : colorStyling.black,
                  }}
                >
                  {t("designer.orientationPortrait")}
                </TypographyLarge>
              </Box>
            </ButtonBase>

            <ButtonBase
              sx={{
                p: 2,
                width: "112px",
                borderRadius: "8px",
                border:
                  orientation === "L"
                    ? "2px solid rgba(28, 78, 216, 0.9)"
                    : "2px solid rgba(28, 78, 216, 0.6)",
                backgroundColor:
                  orientation === "L"
                    ? "rgba(28, 78, 216, 0.1)"
                    : "transparent",
                ".landscapeIcon": {
                  color:
                    orientation === "L"
                      ? colorStyling.primary
                      : colorStyling.black,
                },
                ":hover": {
                  border:
                    orientation === "L"
                      ? ""
                      : "2px solid rgba(28, 78, 216, 0.6)",
                  backgroundColor: "rgba(28, 78, 216, 0.1)",
                },
              }}
              onClick={() => {
                handleSetOrientation("L");
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <LandscapeIcon
                  className="landscapeIcon"
                  fontSize="inherit"
                  color="inherit"
                  sx={{ fontSize: "64px" }}
                />
                <TypographyLarge
                  sx={{
                    color:
                      orientation === "L"
                        ? colorStyling.primary
                        : colorStyling.black,
                  }}
                >
                  {t("designer.orientationLandscape")}
                </TypographyLarge>
              </Box>
            </ButtonBase>
          </Box>
        </StyledBox>
      )}

      <StyledBox>
        <InputLabel>{t("designer.resolutionLabel")}</InputLabel>

        <TextField
          margin="dense"
          fullWidth
          variant="outlined"
          size="small"
          select
          SelectProps={{
            native: true,
          }}
          value={resType}
          error={errorHandler.resolution}
          onChange={(e) => {
            handleSelectedResType(e);
          }}
        >
          {resType === "" && (
            <option value="">{t("designer.resolutionSelect")}</option>
          )}
          {Object.keys(ResolutionType[orientation]).map((res) => {
            const selectedRes = ResolutionType[orientation][res];
            return (
              <option value={res} key={res}>
                {t(`resolution.${selectedRes.id}`)}
              </option>
            );
          })}
        </TextField>

        {resType !== "" && (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ mt: "4px", ml: 1 }}
          >
            <Box display={"flex"} alignContent={"center"} gap={3}>
              <Box>
                <InputLabel sx={{ ml: "11px" }}>
                  {t("designer.widthLabel")}
                </InputLabel>
                <Box display={"flex"} alignItems={"center"}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    placeholder="0"
                    size="small"
                    value={widthInput}
                    autoComplete="off"
                    onChange={(e) => handleChangeReso("width", e)}
                    onKeyDown={(e) => inputOnlyNumbers(e)}
                    InputProps={{ readOnly: !contentRes.custom }}
                    sx={{
                      width: "77px",
                      backgroundColor: contentRes.custom ? "white" : "",
                      "& .MuiInputBase-input": {
                        border:
                          resMinMaxHelp && widthInput < 50
                            ? "1px solid red"
                            : undefined,
                      },
                    }}
                  />
                  <TypographyLarge sx={{ pl: 1, color: "black" }}>
                    px
                  </TypographyLarge>
                </Box>
              </Box>

              <Box>
                <InputLabel sx={{ ml: "11px" }}>
                  {t("designer.heightLabel")}
                </InputLabel>
                <Box display={"flex"} alignItems={"center"}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    placeholder="0"
                    size="small"
                    value={heightInput}
                    autoComplete="off"
                    onChange={(e) => handleChangeReso("height", e)}
                    onKeyDown={(e) => inputOnlyNumbers(e)}
                    InputProps={{ readOnly: !contentRes.custom }}
                    sx={{
                      width: "77px",
                      backgroundColor: contentRes.custom ? "white" : "",
                      "& .MuiInputBase-input": {
                        border:
                          resMinMaxHelp && heightInput < 50
                            ? "1px solid red"
                            : undefined,
                      },
                    }}
                  />
                  <TypographyLarge sx={{ pl: 1, color: "black" }}>
                    px
                  </TypographyLarge>
                </Box>
              </Box>

              {resApplyCondition && (
                <Box display={"flex"} alignItems={"end"}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={applyResolutionChanges}
                    sx={{
                      mb: "4px",
                      color: "#1976d2",
                      borderColor: "#1976d2",
                      backgroundColor: "white",
                      "&:hover": {
                        backgroundColor: "#e3f2fd",
                      },
                    }}
                  >
                    {tCommon("applyBtn")}
                  </Button>
                </Box>
              )}
            </Box>

            {resMinMaxHelp && (
              <Box display={"flex"} width={"90%"}>
                <TypographySmall sx={{ color: "red", fontWeight: 500 }}>
                  {parser(t("designer.resMinMaxHelp"))}
                </TypographySmall>
              </Box>
            )}

            {resInputHelp && (
              <Box display={"flex"} width={"90%"}>
                <TypographySmall sx={{ color: "black" }}>
                  {parser(t("designer.resInputHelp"))}
                </TypographySmall>
              </Box>
            )}
          </Box>
        )}
      </StyledBox>
    </>
  );
};

export default Form;
