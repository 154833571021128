import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import {
  Backdrop,
  Box,
  CircularProgress,
  CssBaseline,
  Toolbar,
} from "@mui/material";
import PreviewBox from "../create/PreviewBox";
import DirectUpload from "../create/DirectUpload";
import ContentTemplateSuccessPrompt from "../ContentTemplateSuccessPrompt";
import ContentTemplateErrorPrompt from "../ContentTemplateErrorPrompt";
import Sidebar from "../../../sidebar/Sidebar";
import { ResolutionType } from "../../../../helpers/resolution";
import {
  setContentLayers,
  setLayerMedias,
  getContent,
  setContentName,
  setContentResolution,
  setCanvasRes,
  setLoadingSubmit,
  setTextLayers,
  setLayerArray,
  setContentTags,
  setDuplicateAsset,
} from "../../../../store/actions/cmsAction";
import {
  SET_CONTENT_DETAIL,
  SET_CONTENT_IS_EDITING,
} from "../../../../store/actionTypes/cmsActionType";
import { colorStyling } from "../../../../helpers/color";
import { logger } from "../../../../helpers/logger";
import { parseVariables } from "../../../../helpers/localize";
import ContainersList from "../create/ContainersList";
import Form from "../create/Form";
import TopBar from "../create/TopBar";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../../../../store/actionTypes/globalActionType";
import GlobalErrorPrompt from "../../../global/GlobalErrorPrompt";
import update from "immutability-helper";

const assetPerLayerLimit = process.env.REACT_APP_ASSET_PER_LAYER_LIMIT || 50;

const EditContentTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    contentDetail,
    loadingDetail,
    contentRes,
    disableDirect,
    cmsDetails,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");

  const { user, features } = useSelector((state) => state.auth);
  const permission = user?.permission;
  const contentPermission = permission?.content;
  const assetPermission = permission?.asset;

  const [firstLoad, setFirstLoad] = useState(false);
  const [resType, setResType] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [uploadBoxActive, setUploadBoxActive] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone({
      disabled: disableDirect || resType.length === 0,
      noClick: true,
      accept: {
        "image/*": [],
        "video/*": [],
      },
    });

  const getLayerAssets = async (layerID, layerAssets) => {
    const parsedAssets = [];
    for (let j = 0; j < layerAssets?.length; j++) {
      const asset = layerAssets[j];
      switch (asset.type) {
        case "VISTAR_ADVERTISEMENT":
        case "HIVESTACK_ADVERTISEMENT":
          parsedAssets.push({
            id: asset.id,
            name: asset.name,
            interval: asset.duration / 1000,
            type: asset.type,
            appType: "ads",
            placeholder: asset.placeholder,
            assetFit: asset.fit ?? "FIT",
          });
          continue;

        default:
          break;
      }

      const fetchAssetData = asset;
      const parsedAsset = {
        id: j + 1,
        assetID: fetchAssetData.id,
        interval: asset.duration / 1000,
        name: asset.name,
        organizationId: asset.organizationId,
        size: asset.size,
        type: asset.type,
        isMuted: asset.isMuted,
        url: fetchAssetData.url,
        thumbnailUrl: fetchAssetData.thumbnailUrl,
        text: fetchAssetData.text,
        assetFit: asset.fit ?? "FIT",
      };

      if (parsedAsset.text) {
        return parsedAsset.text;
      }

      parsedAssets.push(parsedAsset);
    }

    dispatch(setLayerMedias({ layerID, layerMedias: parsedAssets }));
  };

  useEffect(() => {
    if (!user?.id) {
      return;
    }

    if (features?.["CONTENT"] === false) {
      navigate("/");
      return;
    }

    if (!permission) {
      return;
    }

    if (
      !contentPermission?.read ||
      !contentPermission?.update ||
      !assetPermission?.read
    ) {
      navigate("/");
      return;
    }

    if (firstLoad) {
      return;
    }

    setFirstLoad(true);
    dispatch(setDuplicateAsset(null));
    dispatch(
      getContent(
        { id, cache: contentDetail, cacheDetails: cmsDetails },
        async (data) => {
          dispatch(setLoadingSubmit(false));
          dispatch({ type: SET_CONTENT_DETAIL, payload: data });
          dispatch(setContentName(data.name));
          dispatch(setLayerMedias({ reset: true }));

          const existingTags = {};
          if (data.tags?.length > 0) {
            data.tags.forEach((tag) => (existingTags[tag] = true));
          }
          dispatch(setContentTags(existingTags));

          let orientation = "P";
          let potraitFlag = true;
          if (data.width > data.height) {
            orientation = "L";
            potraitFlag = false;
          }

          let customRes = {};
          let resLabel = `${data.width} x ${data.height}`;
          let assignedRes = ResolutionType[orientation][resLabel];
          if (!assignedRes) {
            resLabel = "custom0";
            assignedRes = ResolutionType[orientation][resLabel];
            customRes = { custom: true, free: true };
          } else {
            customRes = { custom: assignedRes.custom, free: false };
          }

          dispatch(
            setContentResolution(
              update(contentRes, {
                $merge: {
                  width: data.width,
                  height: data.height,
                  orientation,
                  label: resLabel,
                  ...customRes,
                },
              })
            )
          );
          setResType(resLabel);

          const canvasRes = {
            width: 0,
            height: 0,
            orientation,
          };
          if (!assignedRes?.free) {
            canvasRes["width"] = potraitFlag ? 360 : 640;
            canvasRes["height"] = potraitFlag ? 640 : 360;
          } else {
            canvasRes["width"] = data.width;
            canvasRes["height"] = data.height;
          }
          dispatch(setCanvasRes(canvasRes));

          // parse data
          const initLayerArray = [];
          const parsedLayers = {};
          const parsedTextLayers = {};
          for (let i = 0; i < data.layers?.length; i++) {
            const layer = data.layers[i];
            const ratiodPosX = Math.round(
              layer.x / (data.width / canvasRes.width)
            );
            const ratiodPosY = Math.round(
              layer.y / (data.height / canvasRes.height)
            );
            const parsedLayer = {
              id: layer.id,
              name: layer.name ?? "",
              active: false,
              dragFlag: true,
            };
            const parsedDim = {
              width: layer.width,
              height: layer.height,
              left: ratiodPosX,
              top: ratiodPosY,
              zIndex: layer.z + 1,
            };

            parsedLayer["dimensions"] = parsedDim;
            parsedLayer["transition"] = layer.transition;

            const textLayer = await getLayerAssets(layer.id, layer.assets);
            if (layer.assets[0].type === "TEXT") {
              parsedLayer["type"] = "text";
              parsedLayer["text"] = {
                text: textLayer?.text,
                family: textLayer?.fontType,
                size: textLayer?.fontSize,
                weight: textLayer?.fontWeight,
                color: textLayer?.color,
                hAlign: textLayer?.horizontalAlignment,
                vAlign: textLayer?.verticalAlignment,
                scrolling: textLayer?.mode === "SCROLLING",
                direction: textLayer?.scrollingDirection,
                speed: Math.round(textLayer?.scrollingSpeed / 40),
              };

              parsedLayer["bgColor"] = textLayer?.backgroundColor;
              parsedDim["lockRatio"] = false;
              parsedTextLayers[parsedLayer.id] = { edit: false };
            } else {
              parsedLayer["type"] = "media";
              // TODO: if background color can be changed (need backend changes)
              parsedLayer["bgColor"] = colorStyling.cms.black;
              parsedDim["lockRatio"] = false;
            }

            parsedLayers[parsedLayer.id] = parsedLayer;
            initLayerArray.push(parsedLayer.id);
          }

          dispatch(setLayerArray(initLayerArray));
          dispatch(setContentLayers(parsedLayers));
          dispatch(setTextLayers(parsedTextLayers));
          dispatch({ type: SET_CONTENT_IS_EDITING, payload: true });
        }
      )
    );

    return () => {
      dispatch({ type: SET_CONTENT_IS_EDITING, payload: false });
      dispatch(setLoadingSubmit(false));
      dispatch(setContentName(""));
      dispatch(setContentTags({}));
      dispatch(setContentLayers({}));
      dispatch(setTextLayers({}));
      dispatch(setLayerArray([]));
      dispatch(setLayerMedias({ reset: true }));
      dispatch(setDuplicateAsset(null));
      dispatch(
        setContentResolution({
          width: 0,
          height: 0,
          custom: false,
          orientation: "P",
          label: "",
        })
      );
      dispatch(
        setCanvasRes({
          width: 360,
          height: 640,
          orientation: "P",
        })
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, features]);

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      logger.log(`acceptedFiles:`, acceptedFiles);
      if (acceptedFiles.length > assetPerLayerLimit) {
        logger.log(`LIMITED!`, assetPerLayerLimit);
        dispatch({ type: SET_GLOBAL_ERROR, payload: true });
        dispatch({
          type: SET_GLOBAL_ERROR_MESSAGE,
          payload: parseVariables(t("error.directUploadLimitReached"), {
            maxCount: assetPerLayerLimit,
            limit: assetPerLayerLimit,
          }),
        });
        return true;
      }

      setLoadingFile(true);
      setUploadBoxActive(true);
      setLoadingFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  if (!features?.enabled) {
    return (
      <div style={{ display: "flex" }}>
        <Sidebar />
        <Box component="main" sx={{ flexGrow: 1, p: 1 }}></Box>
      </div>
    );
  }

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <GlobalErrorPrompt />

      <Box component="main" sx={{ flexGrow: 1, p: 1, overflowX: "auto" }}>
        <Toolbar />
        <CssBaseline />

        <TopBar setNameValidation={setNameValidation} />

        <Box height={"85vh"} {...getRootProps()}>
          <input {...getInputProps()} />

          <DirectUpload
            resType={resType}
            acceptedFiles={acceptedFiles}
            isDragActive={isDragActive}
            uploadBoxActive={uploadBoxActive}
            setUploadBoxActive={setUploadBoxActive}
            loadingFile={loadingFile}
            setLoadingFile={setLoadingFile}
          />

          {(!isDragActive || resType.length === 0) && (
            <>
              <ContentTemplateSuccessPrompt />
              <ContentTemplateErrorPrompt />

              <Box display={"flex"} gap={3} sx={{ px: 2 }}>
                <Box
                  sx={{
                    pr: 2,
                    width: "60%",
                    overflowY: "auto",
                    maxHeight: "80vh",
                  }}
                >
                  <Form
                    resType={resType}
                    setResType={setResType}
                    nameValidation={nameValidation}
                    setNameValidation={setNameValidation}
                  />
                  <ContainersList resType={resType} />
                </Box>

                <PreviewBox />
              </Box>
            </>
          )}
        </Box>

        <Backdrop
          sx={{
            color: colorStyling.white.inactive,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={!permission || loadingDetail}
          onClick={null}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </div>
  );
};

export default EditContentTemplate;
