import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AssetsManagerViewAsset from "./AssetsManagerViewAsset";
import AssetDeleteConfirmation from "./AssetDeleteConfirmation";
import { colorStyling } from "../../../helpers/color";
import {
  getContentAssets,
  removeAsset,
  updateAsset,
} from "../../../store/actions/assetAction";
import { TypographyNormal, TypographySmall } from "../../customComponent";

const AssetsManagerItem = ({
  data,
  index,
  pageData,
  assetPermission,
  currentPayload,
  selectItem,
  unselectItem,
  selectedFlag,
}) => {
  const dispatch = useDispatch();
  const { contentAssets, assetSorting, assetsTags } = useSelector(
    (state) => state.asset
  );

  const { t } = useTranslation("assetmanager");

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [isImage, setIsImage] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  useEffect(() => {
    switch (data.type) {
      case "IMAGE":
        setIsImage(true);
        break;

      case "VIDEO":
        setIsVideo(true);
        break;

      default:
        break;
    }

    return () => {
      setIsImage(false);
      setIsVideo(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDeleteDialog = () => {
    setOpenDeleteConfirmation(true);
  };

  const closeDeleteDialog = () => {
    setOpenDeleteConfirmation(false);
  };

  const dataNumber = useMemo(() => {
    const originalNum = pageData.page * pageData.rowsPerPage + index + 1;
    if (assetSorting.by === "id" && assetSorting.direction === "desc") {
      return contentAssets.totalItem - originalNum + 1;
    }
    return originalNum;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentAssets, assetSorting]);

  const updateContentThenRefresh = async (updatePayload, callback) => {
    const payload = currentPayload;
    dispatch(
      updateAsset(updatePayload, () => {
        if (callback) callback();
        dispatch(getContentAssets({ payload, cache: contentAssets }));
      })
    );
  };

  const removeContentThenRefresh = async () => {
    setLoadingDelete(true);
    const payload = currentPayload;
    unselectItem(data.id);
    dispatch(
      removeAsset(data, () => {
        dispatch(getContentAssets({ payload, cache: contentAssets }));
      })
    );
  };

  const handleCheckbox = () => {
    const updated = !selectedFlag;
    if (updated === true) {
      selectItem(data);
    } else {
      unselectItem(data.id);
    }
  };

  const tags = useMemo(() => {
    return assetsTags[data.id] || data.tags;
  }, [data, assetsTags]);

  const parseTooltipTitle = (which) => {
    switch (which) {
      case "delete":
        if (assetPermission?.delete) {
          return data.canDelete
            ? t("asset.deleteTip")
            : t("asset.cantDeleteTip");
        }
        break;

      case "update":
        if (assetPermission?.update) {
          return t("asset.editTip");
        }
        break;

      default:
        break;
    }

    return t("asset.noPermissionTip");
  };

  const parseTypeBgColor = (asset) => {
    // ADDED when needed for other file types:
    // burlywood, darkorange, darksalmon, skyblue, sandybrown, thistle
    switch (asset.type) {
      case "IMAGE":
        return "sandybrown";

      case "VIDEO":
        return "skyblue";

      default:
        return "gainsboro";
    }
  };

  const disableDeleteCondition = () => {
    return !data.canDelete || !assetPermission?.delete;
  };

  return (
    <>
      <AssetDeleteConfirmation
        asset={data}
        assetType={{ isImage, isVideo }}
        open={openDeleteConfirmation}
        closeDialog={closeDeleteDialog}
        handleConfirm={removeContentThenRefresh}
        loading={loadingDelete}
      />

      <TableRow
        sx={{
          ...((index + 1) % 2 !== 0
            ? {
                backgroundColor: colorStyling.white.hover,
              }
            : {
                backgroundColor: colorStyling.white.full,
              }),
        }}
      >
        <TableCell align="center">{dataNumber}</TableCell>
        <TableCell align="left">
          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            width={"fit-content"}
          >
            <Box width={"100%"} display={"flex"}>
              <Tooltip
                arrow
                disableInteractive
                title={
                  <TypographySmall sx={{ color: "white", textAlign: "center" }}>
                    {data.name}
                  </TypographySmall>
                }
              >
                <Box sx={{ maxWidth: "333px" }}>
                  <TypographyNormal
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {data.name}
                  </TypographyNormal>
                </Box>
              </Tooltip>
            </Box>

            {tags?.length > 0 && (
              <Box
                width={"100%"}
                display={"flex"}
                gap={1}
                sx={{ mt: "2px", ml: 2, flexWrap: "wrap" }}
              >
                {tags.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    size="small"
                    sx={{ color: "white", backgroundColor: "mediumseagreen" }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </TableCell>
        <TableCell align="center">{data.campaignName ?? "—"}</TableCell>
        <TableCell align="center">
          {data?.createdAt ? data.createdAt.split("T")[0] : "—"}
        </TableCell>
        <TableCell align="center">
          <Box
            display={"flex"}
            bgcolor={parseTypeBgColor(data)}
            paddingY={"4px"}
            width={"58px"}
            justifyContent={"center"}
            sx={{
              top: 0,
              left: 0,
              width: "100%",
              borderRadius: "8px",
            }}
          >
            <TypographySmall fontWeight={"medium"}>{data.type}</TypographySmall>
          </Box>
        </TableCell>
        <TableCell>
          <Box display={"flex"} justifyContent={"end"}>
            <Box display={"flex"} alignItems={"center"}>
              <Checkbox
                checked={selectedFlag}
                size="small"
                disabled={disableDeleteCondition()}
                onClick={handleCheckbox}
                sx={{ padding: "4px" }}
              />
            </Box>

            <Tooltip
              arrow
              disableInteractive
              title={parseTooltipTitle("delete")}
            >
              <Box sx={{ width: "fit-content" }}>
                <IconButton
                  color="warning"
                  variant="contained"
                  disabled={loadingDelete || disableDeleteCondition()}
                  onClick={() => openDeleteDialog()}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Tooltip>

            <AssetsManagerViewAsset
              asset={data}
              assetType={{ isImage, isVideo }}
              updatePermission={assetPermission?.update}
              handleUpdate={updateContentThenRefresh}
            />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AssetsManagerItem;
