import React, { useState, useMemo, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Link, Dialog } from "@mui/material";
import { colorStyling } from "../../../helpers/color";
import { TypographyNormal, TypographySmall } from "../../customComponent";
import LiveRecordingIcon from "../../../icons/LiveRecordingIcon";
import { logger } from "../../../helpers/logger";
import Hls from "hls.js";

const LIVESTREAM_URL_PREFIX = process.env.REACT_APP_LIVESTREAM_URL_PREFIX;
const LIVESTREAM_URL_SUFFIX = process.env.REACT_APP_LIVESTREAM_URL_SUFFIX;

const LiveStream = ({ viewer }) => {
  const { t } = useTranslation("viewer");
  const { features } = useSelector((state) => state.auth);

  const videoRef = useRef();
  const hlsRef = useRef(null);

  const [openVideoStream, setOpenVideoStream] = useState(false);

  const openStreamDialogue = () => {
    setOpenVideoStream(true);
  };

  const closeStreamDialogue = () => {
    setOpenVideoStream(false);
  };

  // Listen for timeupdate to enforce live point
  const handleStreamTimeUpdate = () => {
    const video = videoRef.current;
    if (!video?.seekable) {
      return;
    }

    const bufferTime = 25; // Allow a 25-second buffer before the live point
    const bufferSkipForward = 5; // Skip 5 seconds forward after seek point refreshed

    if (video.seekable.length > 0) {
      const livePoint = video.seekable.end(video.seekable.length - 1);
      const bufferLimit = livePoint - bufferTime;

      // If current time is less than the buffer limit, set to buffer limit
      if (video.currentTime < bufferLimit) {
        video.currentTime = bufferLimit + bufferSkipForward;
      }
    }
  };

  useEffect(() => {
    if (!openVideoStream && hlsRef.current) {
      const video = videoRef.current;
      hlsRef.current.destroy();
      video.removeEventListener("timeupdate", handleStreamTimeUpdate);
    }
  }, [openVideoStream]);

  const streamLink = useMemo(() => {
    return "" + LIVESTREAM_URL_PREFIX + viewer.id + LIVESTREAM_URL_SUFFIX;
  }, [viewer]);

  if (
    !features["SCREEN.LIVESTREAM.UI"] ||
    !viewer ||
    !viewer.canStream ||
    !viewer.isStreaming
  ) {
    return <></>;
  }

  return (
    <>
      <Link
        href="#"
        underline="none"
        component={"div"}
        onClick={openStreamDialogue}
        sx={{
          margin: 1,
          // width: "128px", // TODO: When using screenshot thumbnail
          position: "relative",
          cursor: "pointer",
          ":hover": {
            opacity: 0.9,
          },
        }}
      >
        <Box
          sx={{
            p: 1,
            backgroundColor: colorStyling.white.full,
            border: `1px solid ${colorStyling.white.border}`,
            borderRadius: "6px",
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ mb: 1 }}
          >
            <LiveRecordingIcon width={12} height={12} sx={{ mr: 1 }} />
            <TypographySmall sx={{ fontWeight: 400 }}>
              {t("item.liveLabel")}
              {/* TODO: When using screenshot thumbnail */}
              {/* Live Feed */}
            </TypographySmall>
            <LiveRecordingIcon width={12} height={12} sx={{ ml: 1 }} />
          </Box>

          <Box display={"flex"} justifyContent={"center"}>
            <TypographyNormal sx={{ px: 1 }}>
              {t("item.clickViewStreamHint")}
            </TypographyNormal>
            {/* TODO: Use latest viewer automatic screenshot as thumbnail */}
            {/* <img
                draggable={false}
                src={screen}
                alt="viewer thumbnail"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
              <PlayCircleIcon
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  color: colorStyling.white.full,
                  fontSize: "33px",
                }}
              /> */}
          </Box>
        </Box>
      </Link>

      <Dialog
        maxWidth="md"
        fullWidth
        open={openVideoStream}
        onClose={closeStreamDialogue}
        PaperProps={{
          sx: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
            backgroundColor: colorStyling.black,
          },
        }}
      >
        {openVideoStream && (
          <video
            ref={(video) => {
              if (video) {
                videoRef.current = video;
                hlsRef.current = new Hls({
                  debug: false,
                  liveDurationInfinity: true,
                });
                const hls = hlsRef.current;
                try {
                  if (Hls.isSupported()) {
                    hls.loadSource(streamLink);
                    hls.attachMedia(videoRef.current);
                    hls.on(Hls.Events.ERROR, (err, data) => {
                      logger.error(err, data);
                    });
                    hls.on(Hls.Events.MANIFEST_PARSED, () => {
                      video.play();
                    });
                    video.addEventListener(
                      "timeupdate",
                      handleStreamTimeUpdate
                    );
                  } else {
                    // TODO: Error handling
                    const errMessage = `HLS not supported!`;
                    throw new Error(errMessage);
                  }
                } catch (error) {
                  logger.error(error);
                }
              }
            }}
            draggable={false}
            alt="viewer thumbnail"
            controls
            autoPlay
            onClick={null}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        )}
      </Dialog>
    </>
  );
};

export default LiveStream;
