import {
  SET_ASSET_FIRST_LOAD,
  SET_CONTENT_ASSET,
  SET_ASSETS_TAGS,
  SET_ASSET_FILTER,
  SET_ASSET_SORTING,
  SET_ASSET_ROWS_PER_PAGE,
  SET_ASSET_LIST_AFTER_REMOVE,
  SET_ERROR_MESSAGE_ASSET_PAGE,
  SET_ERROR_PROMPT_ASSET_PAGE,
  SET_LOADING_CONTENT_ASSET,
  SET_LOADING_UPDATE_ASSET,
  SET_SUCCESS_MESSAGE_ASSET_PAGE,
  SET_SUCCESS_PROMPT_ASSET_PAGE,
  SET_INFO_MESSAGE_ASSET_PAGE,
  SET_INFO_PROMPT_ASSET_PAGE,
} from "../actionTypes/assetActionType";

const initialState = {
  firstLoad: false,
  contentAssets: {
    items: [],
    totalItem: 0,
  },
  assetsTags: {},
  assetFilter: {
    searchBy: "name",
    searchType: "",
    searchInput: "",
    searchDate: "",
    searchByTags: [],
  },
  assetSorting: {
    by: "id",
    direction: "asc",
  },
  rowsPerPage: 10,
  contentAssetStartFilter: false,
  loadingContentAsset: false,
  loadingUpdate: false,
  isSuccessPromptAssetPage: false,
  isErrorPromptAssetPage: false,
  isInfoPromptAssetPage: false,
  successMessageAssetPage: "",
  errorMessageAssetPage: "",
  infoMessageAssetPage: "",
};

export const assetReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let data;
  let updated;
  let callback;
  switch (type) {
    case SET_ASSET_FIRST_LOAD:
      return { ...state, firstLoad: payload };

    case SET_CONTENT_ASSET:
      return { ...state, contentAssets: payload };

    case SET_ASSETS_TAGS:
      updated = { ...state.assetsTags };
      updated[payload.id] = payload.tags;
      return { ...state, assetsTags: updated };

    case SET_ASSET_FILTER:
      return { ...state, assetFilter: payload };

    case SET_ASSET_SORTING:
      if (payload.reset === true) {
        return {
          ...state,
          assetSorting: {
            by: "id",
            direction: "asc",
          },
        };
      }
      updated = { ...state.assetSorting, ...payload };
      return { ...state, assetSorting: updated };

    case SET_ASSET_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SET_ASSET_LIST_AFTER_REMOVE:
      const { batchIDs } = payload;

      data = state.contentAssets.items;
      if (batchIDs?.length > 0) {
        updated = data.filter(
          (dt) => !batchIDs.includes(dt.id)
        );

        callback = payload.callback;
        callback();
      } else {
        updated = data.filter(
          (dt) => dt.id.toString() !== payload.id.toString()
        );
      }

      return {
        ...state,
        contentAssets: {
          items: updated,
          totalItem: state.contentAssets.totalItem - (batchIDs?.length ?? 1),
        },
      };

    case SET_LOADING_CONTENT_ASSET:
      return { ...state, loadingContentAsset: payload };

    case SET_LOADING_UPDATE_ASSET:
      return { ...state, loadingUpdate: payload };

    case SET_SUCCESS_PROMPT_ASSET_PAGE:
      return { ...state, isSuccessPromptAssetPage: payload };

    case SET_SUCCESS_MESSAGE_ASSET_PAGE:
      return { ...state, successMessageAssetPage: payload };

    case SET_ERROR_PROMPT_ASSET_PAGE:
      return { ...state, isErrorPromptAssetPage: payload };

    case SET_ERROR_MESSAGE_ASSET_PAGE:
      return { ...state, errorMessageAssetPage: payload };

    case SET_INFO_PROMPT_ASSET_PAGE:
      return { ...state, isInfoPromptAssetPage: payload };

    case SET_INFO_MESSAGE_ASSET_PAGE:
      return { ...state, infoMessageAssetPage: payload };

    default:
      return state;
  }
};
