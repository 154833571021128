import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  SET_ASSET_FILTER,
  SET_ASSET_SORTING,
} from "../../../store/actionTypes/assetActionType";
import { colorStyling } from "../../../helpers/color";
import { TypographySmall, TypographyXSmall } from "../../customComponent";
import parser from "html-react-parser";
import dayjs from "dayjs";

const initFilter = {
  searchBy: "name",
  searchType: "",
  searchInput: "",
};

const AssetsManagerFilter = () => {
  const dispatch = useDispatch();
  const { assetFilter } = useSelector((state) => state.asset);

  const { t } = useTranslation("assetmanager");
  const { t: tCommon } = useTranslation();

  const [searchBy, setSearchBy] = useState("name");
  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState("");
  const [searchDate, setSearchDate] = useState("");

  const [openAdvancedFilter, setOpenAdvancedFilter] = useState(false);
  const [searchByTagInputs, setSearchByTagInputs] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [tagValidation, setTagValidation] = useState("");

  const handleToggleAdvancedFilter = () => {
    setOpenAdvancedFilter(!openAdvancedFilter);
  };

  const handleTagInputChange = (event) => {
    const input = event.target.value;
    // max input to 20 characters
    if (input?.length > 20) {
      setTagValidation(t("filter.tagInputReachedMax"));
      const textAfter = input.slice(0, 20);
      setTagInput(textAfter);
      return;
    }
    setTagValidation("");
    setTagInput(input);
  };

  const handleTagInputPress = (event) => {
    if (event.key === "Backspace" && tagInput === "") {
      if (searchByTagInputs.length === 0) {
        return;
      }

      const updatedTags = [...searchByTagInputs];
      updatedTags.pop();
      setSearchByTagInputs(updatedTags);
      return;
    }
    if (event.key === "Enter") {
      const tag = tagInput.trim();
      if (tag === "") {
        setTagInput("");
        return;
      }
      if (searchByTagInputs.includes(tag)) {
        setTagValidation(t("asset.tagErrorAlreadyExist"));
        return;
      }
      if (/[^a-zA-Z0-9]/.test(tag)) {
        setTagValidation(t("asset.tagErrorInvalid"));
        return;
      }

      setSearchByTagInputs([...searchByTagInputs, tagInput.trim()]);
      setTagValidation("");
      setTagInput("");
    }
  };

  const handleTagRemove = (tagToRemove) => {
    setSearchByTagInputs(
      searchByTagInputs.filter((tag) => tag !== tagToRemove)
    );
  };

  useEffect(() => {
    setSearchBy(assetFilter.searchBy);
    setSearchType(assetFilter.searchType);
    setSearchInput(assetFilter.searchInput);
    setSearchDate(
      assetFilter.searchDate
        ? dayjs(assetFilter.searchDate).format("YYYY-MM-DD")
        : ""
    );
    if (assetFilter.searchByTags?.length > 0) {
      setSearchByTagInputs(assetFilter.searchByTags);
      setOpenAdvancedFilter(true);
    }
  }, [assetFilter]);

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_ASSET_FILTER,
      payload,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let filterPayload = { ...assetFilter };
    filterPayload["searchType"] = searchType;
    filterPayload["type"] = searchType;

    filterPayload["searchBy"] = searchBy;
    filterPayload["searchInput"] = searchInput;
    filterPayload["searchByTags"] = searchByTagInputs;

    switch (searchBy) {
      default:
      case "name":
        filterPayload["nameLike"] = searchInput;
        delete filterPayload["campaignNameLike"];
        break;

      case "campaignName":
        filterPayload["campaignNameLike"] = searchInput;
        delete filterPayload["nameLike"];
        break;
    }

    if (searchDate.length > 0) {
      filterPayload["searchDate"] = searchDate;
    } else {
      filterPayload["searchDate"] = "";
    }

    delete filterPayload["sortBy"];
    dispatch({
      type: SET_ASSET_SORTING,
      payload: {
        reset: true,
      },
    });

    handleDispatchFilter({ ...filterPayload });
  };

  const handleRefresh = () => {
    handleDispatchFilter(initFilter);
    setSearchBy("name");
    setSearchInput("");
    setSearchType("");
    setSearchDate("");
    dispatch({
      type: SET_ASSET_SORTING,
      payload: {
        reset: true,
      },
    });
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  return (
    <Box>
      <Paper
        component="section"
        elevation={2}
        sx={{ p: 2, mt: 2, borderRadius: "6px" }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            my: 0.5,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 2 }}>
              <TextField
                size="small"
                label={t("filter.searchByLabel")}
                select
                SelectProps={{ native: true }}
                value={searchBy}
                onChange={handleSearchByChange}
                sx={{ width: "150px" }}
              >
                <option key="name" value="name">
                  {t("filter.searchByName")}
                </option>
                <option key="campaignName" value="campaignName">
                  {t("filter.searchByCampaignName")}
                </option>
              </TextField>
            </Box>
          </Box>

          <Box sx={{ mr: 2, width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              placeholder={t("filter.searchInputPlaceholder")}
              type="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </Box>

          <TextField
            size="small"
            fullWidth
            select
            SelectProps={{ native: true }}
            value={searchType}
            onChange={(e) => setSearchType(e.target.value)}
            sx={{ mr: 2, maxWidth: "200px" }}
          >
            <option key="default" value="" defaultValue>
              {t("filter.searchTypeAny")}
            </option>
            <option key="IMAGE" value="IMAGE">
              {t("filter.searchTypeImage")}
            </option>
            <option key="VIDEO" value="VIDEO">
              {t("filter.searchTypeVideo")}
            </option>
          </TextField>

          <TextField
            type="date"
            size="small"
            label={t("filter.searchDateLabel")}
            fullWidth
            value={searchDate}
            onChange={(e) => setSearchDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mr: 2 }}
          />

          <Button type="submit" variant="contained">
            {tCommon("searchBtn")}
          </Button>

          <IconButton
            onClick={handleRefresh}
            sx={{ color: colorStyling.black, ml: 1 }}
          >
            <Tooltip title={tCommon("resetFilterTip")}>
              <RestartAltIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>

        <Collapse in={openAdvancedFilter}>
          <Box
            sx={{
              mt: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box width={"50%"}>
              <TextField
                type="Search"
                size="small"
                fullWidth
                autoComplete="off"
                label={t("filter.searchTagsLabel")}
                placeholder={t("filter.searchByTagsPlaceholder")}
                value={tagInput}
                onChange={handleTagInputChange}
                onKeyDown={handleTagInputPress}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  startAdornment: (
                    <Box
                      display="flex"
                      gap={1}
                      height={"auto"}
                      maxWidth={"200px"}
                      sx={{
                        mr: 1,
                        my: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {searchByTagInputs.map((item, index) => (
                        <Chip
                          key={index}
                          label={item}
                          size="small"
                          color="success"
                          onDelete={() => handleTagRemove(item)}
                          sx={{
                            color: "white",
                            backgroundColor: "mediumseagreen",
                          }}
                        />
                      ))}
                    </Box>
                  ),
                }}
              />
              <TypographyXSmall sx={{ ml: 1 }}>
                {parser(t("asset.assetTagsHint"))}
              </TypographyXSmall>

              <TypographySmall
                sx={{
                  ml: 1,
                  color: colorStyling.red,
                  whiteSpace: "nowrap",
                }}
              >
                {tagValidation}
              </TypographySmall>
            </Box>
          </Box>
        </Collapse>
      </Paper>

      <Box sx={{ mb: 2, ml: 2 }}>
        <Tooltip arrow disableInteractive title={t("filter.openAdvancedTip")}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleToggleAdvancedFilter}
            sx={{ p: 0, mt: "-4px", borderRadius: "0 0 10px 10px" }}
          >
            {openAdvancedFilter ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AssetsManagerFilter;
